
$lightColor: #f1ebe7;
$redColor: #e74c3c;
$hColor: #e71e08;
$font1: 'Source Sans Pro', sans-serif;
$font2: 'Open Sans', sans-serif;
$blackColor: #202020;
$errorColor: #c32929;
$mobileMenuBreakpoint: 920px;
@mixin justifyFix {
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
    }
}

@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin placeholder($color, $font) {
    &::-webkit-input-placeholder {
        color: $color;
        font: $font;
        opacity: 1;
        font-style: normal;
        
    }
    &::-moz-placeholder {
        color: $color;
        font: $font;
        opacity: 1;
        font-style: normal;
    }
    &:-moz-placeholder {
        color: $color;
        font: $font;
        opacity: 1;
        font-style: normal;
    }
    &:-ms-input-placeholder {
        color: $color;
        font: $font;
        opacity: 1;
        font-style: normal;
    }
}

@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
    
    $k: ($f-max - $f-min)/($w-max - $w-min);
    $b: $f-min - $k * $w-min;
    
    $b: $b;
    
    @return calc( #{$k} * 100vw + #{$b} );
}


@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max) {
    font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    @media screen and (max-width: $w-min) {
        font-size: $f-min;
    }
    @media screen and (min-width: $w-max) {
        font-size: $f-max;
    }
}

@function calcFluidValue($f-min, $f-max, $w-min, $w-max, $units: px) {
    
    $k: ($f-max - $f-min)/($w-max - $w-min);
    $b: $f-min - $k * $w-min;
    
    $b: $b;
    
    @return calc( #{$k} * 100vw + #{$b} );
}
@mixin fluidValue($property,$f-min, $f-max, $w-min, $w-max) {
    #{$property}: calcFluidValue($f-min, $f-max, $w-min, $w-max, px);
    @media screen and (max-width: $w-min) {
        #{$property}: $f-min;
    }
    @media screen and (min-width: $w-max) {
        #{$property}: $f-max;
    }
}

