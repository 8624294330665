.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}
@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@keyframes upAndDown {
    0%, 100% {
        transform: translateY(-10px) rotateZ(90deg);
    }
    
    50% {
        transform: translateY(10px) rotateZ(90deg);
    }
}

@keyframes loading {
    0% {
        transform: rotateZ(0);
    }

    50% {
        opacity: .5;
        transform: rotateZ(180deg);
    }

    100% {
        transform: rotateZ(360deg);
    }

}

.permit-content {
    //display: none;
}