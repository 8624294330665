@import '_vars';
@import '_reset';
//@import '_fonts';
@import '_common';

html {
    font-size: 18px;
}

body {
    color: #333;
    min-width: 320px;
    max-width: 1920px;
    margin: 0 auto;
    font: 400 16px/1.4 $font1;
}

.page-wrapper {
    position: relative;
}
.wrapper {
    max-width: 1170px;
    margin: 0 auto;
}

header {
    position: fixed;
    z-index: 5;
    width: 100%;
    top: 0;
    left: 0;
    background-color:transparent;
    padding: 30px 0;
    transition: all .2s;
    .h-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &.fixed {
        padding: 10px 0;
        background-color: rgba(#000, .8);
        .main-nav {
            li {
                a {
                    &::after {
                        bottom: 4px;
                    }
                }
            }
        }
    }
}

.main-screen {
    height: 100vh;
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 123px;
        background: url(../img/rect_mainscreen.png) no-repeat center center;
    }
    .video-bg {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            height: auto;
            min-width: 101%;
            min-height: 101%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            -o-object-fit: cover;
            object-fit: cover;
        }
        
        &::after {
            content: '';
            display: block;
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#000, .6);
        }
    }
    .wrapper {
        position: relative;
        z-index: 4;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__content {
        text-align: center;
        color: $lightColor;
        .subtitle {
            @include fluidValue(font-size, 18px, 24px, 380px, 1920px);
            margin-bottom: 30px;
        }
        .main-title {
            @include fluidValue(font-size, 50px, 72px, 380px, 1920px);
            letter-spacing: 4px;
            margin-bottom: 40px;
        }
        .line {
            margin-bottom: 40px;
        }
    }
    &__text {
        max-width: 585px;
        margin: 0 auto 60px auto;
    }
    &__btns {
        .btn{
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .arrow-down {
        position: absolute;
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(50%);
        padding: 5px;
        border-radius: 50%;
        z-index: 5;
        cursor: pointer;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: red;
            display: none;
        }
        &__content {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #f4f5f9;
            position: relative;
            z-index: 5;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        svg {
            width: 22px;
            fill: $redColor;
            
            animation: upAndDown 2s linear infinite;
        }
    }
}
.main-nav {
    ul {
        display: flex;
    }
    li {
        @include fluidValue(margin-right, 20px, 55px, 380px, 1920px);
        &:last-child {
            margin-right: 0;
        }
        a {
            color: #f4f5f9;
            @include fluidValue(font-size, 12px, 14px, 380px, 1920px);
            text-transform: uppercase;
            position: relative;
            display: block;
            padding: 10px 4px;
            transition: all .3s;
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 1px;
                transition: all .3s;
                background-color: $redColor;
            }
            &:hover, &.active {
                color: $redColor;
                &::after {
                    width: 100%;
                }
            }
        }
    }
}

// advantages
.about-us {
    padding-top: 100px;
}
.advantages {
    padding-bottom: 150px;
    &__container {
        display: flex;
        margin: 0 -20px;
    }
    
}
.advantage-item {
    flex-basis: 25%;
    padding: 0 20px;
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    &__img {
        width: 73px;
        height: 73px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 25px;
        img {
            position: relative;
            z-index: 2;
        }
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid #e5e5e5;
            z-index: 1;
            box-sizing: border-box;
            transition: all .3s;
        }
    }
    &__title {
        font: 700 18px/1.1 $font2;
        color: #6a6a6a;
        transition: all .3s;
        margin-bottom: 16px;
        &::after {
            content: '';
            display: block;
            width: 42px;
            height: 2px;
            background-color: #888;
            margin: 17px auto 0 auto;
        }
    }
    &__text {
        color: #999;
        font-size: 12px;
    }
    &:hover & {
        &__img {
            &::after {
                transform: rotateZ(45deg);
                border-color: $redColor;
            }
        }
        &__title {
            color: $redColor;
        }
    }
}
// advantages END

// our-services
.our-services {
    &__wrapper {
        display: flex;
        justify-content: space-between;
    }
    &__left {
        flex-basis: 55%;
        background-color: $blackColor;
        display: flex;
        align-items: center;
    }
    &__img {
        flex-basis: 45%;
    }
    &__content {
        width: 100%;
        color: $lightColor;
        text-align: right;
        .heading {
            text-align: right;
            padding-right: 82px;
            margin-bottom: 65px;
            line-height: 1;
            @include fluidValue(font-size, 24px, 36px, 380px, 1920px);
            span {
                display: inline-block;
                vertical-align: middle;
            }
            &::after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 6px;
                height: 36px;
                margin-left: 46px;
                background-color: $redColor;
            }
        }
    }
    .service-item {
        display: flex;
        align-items: center;
        margin-bottom: 65px;
        justify-content: flex-end;
        &:last-child {
            margin-bottom: 0;
        }
        &__info {
            margin-right: 7px;
        }
        &__title {
            color: $redColor;
            margin-bottom: 14px;
            @include fluidValue(font-size, 14px, 18px, 380px, 1920px);
        }
        &__text {
            @include fluidValue(font-size, 11px, 14px, 380px, 1920px);
            color: #999;
            max-width: 565px;
        }
        &__img {
            transform: translateX(50%);
        }
    }
}
// our-services END

//recent works
.recent-works {
    margin-top: 120px;
    .line {
        margin-bottom: 50px;
    }
}
//recent works EMD

footer {
    padding: 20px 0;
    background-color: $blackColor;
}

@import "animations";
@import "smp.scss";