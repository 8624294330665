.smp {
    &-container {
        position: relative;
    }
    &-btn {
        cursor: pointer;
    }
    &-content {
        display: none;
        position: absolute;
        z-index: 1000;
        top: 100%;
        background-color: #000;
    }
}