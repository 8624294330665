a {
    transition: all 0.3s;
    text-decoration: none;
    &:focus {
        text-decoration: none;
    }
    &:hover {
        color: #828cd1;
    }
}
input, textarea {
    &.validate-error {
        box-shadow: 0 0 1px 1px #ff003e;
    }
}

.img-responsive {
    max-width: 100%;
    display: block;
}

.heading {
    font: 700 40px/1 $font2;
    &-section {
        color: #6a6a6a;
        @include fluidValue(font-size, 24px, 36px, 380px, 1920px);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 20px;
    }
}

.section-subtitle {
    color: #999;
    @include fluidValue(font-size, 12px, 14px, 380px, 1920px);
    text-align: center;
    margin-bottom: 25px;
}

.line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 198px;
    margin: 0 auto;
    &::after, &::before {
        content: '';
        display: block;
        width: 80px;
        height: 1px;
        background-color: $lightColor;
    }
    .circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $redColor;
    }
    
    &-gray {
        &::after, &::before {
            background-color: #999;
        }
    }
}

.btn {
    display: inline-block;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    line-height: 1;
    width: 100%;
    max-width: 225px;
    padding: 20px 10px;
    cursor: pointer;
    transition: all .3s;
    border-radius: 2px;
    text-transform: uppercase;
    &-red {
        background-color: $redColor;
        border-color: $redColor;
        box-shadow: 0 2px 5px 5px rgba(#000, .2);
        &:hover {
            background-color: $hColor;
        }
    }
    &:hover {
        transform: translateY(-5%);
    }
}

.double-circle {
    position: relative;
    width: 73px;
    height: 73px;
    border-radius: 50%;
    background-color: rgba(#fff, .25);
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
        position: relative;
        z-index: 5;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 4px;
        right: 4px;
        border-radius: 50%;
        z-index: 2;
        background-color: $lightColor;
    }
}
